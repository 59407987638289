import React, { Component, useState, handleShow } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import Logo from "../../../images/logo.png"
import En from "../../../images/en.jpg"
import Tr from "../../../images/tr.jpg"
import { withTranslation, useTranslation, Trans } from 'react-i18next';
import { isMobile } from "react-device-detect";
  

const lngs = {
	en: { nativeName: 'English' },
	tr: { nativeName: 'Türkçe' }
};


class Header1 extends Component {
	constructor(props) {
		super(props);
		this.state = {


			data3: [],
			data4: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}



	componentDidMount() {
		const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getConsultancyCategoryCastor/select/";
		const apiUrl4 = "https://getjob.stechomeyazilim.info:5101/getEngineeringServicesCategoryCastor/select/";

		// Fetch data for consultancy
		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("Consultancy API'den veri çekme hatası:", error);
			});

		// Fetch data for engineering services
		fetch(apiUrl4)
			.then((response) => response.json())
			.then((data4) => {
				this.setState({ data4 });
				console.log("data4", data4);
			})
			.catch((error) => {
				console.error("Engineering Services API'den veri çekme hatası:", error);
			});

		// Cart Dropdown 
		var cartBtn = document.getElementById("cartBtn")
		var cartDropdown = document.querySelector(".cart-dropdown")

		cartBtn.addEventListener('click', function () {
			cartDropdown.classList.toggle("show")
		})

		// Search Form Popup
		var searchBtn = document.getElementById("quik-search-btn")
		var searchForm = document.querySelector(".nav-search-bar")
		var closeBtn = document.getElementById("search-remove")

		searchBtn.addEventListener('click', function () {
			searchForm.classList.add("show")
		})

		closeBtn.addEventListener('click', function () {
			searchForm.classList.remove("show")
		})

		// Mobile Menu sidebar function
		var btn = document.querySelector('.menuicon');
		var nav = document.querySelector('.menu-links');

		function toggleFunc() {
			btn.classList.toggle("open");
			nav.classList.toggle("show");
		}

		btn.addEventListener('click', toggleFunc);

		// Mobile Submenu open close function
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener('click', function () { menuClick(this) });
		}

		function menuClick(current) {
			const active = current.classList.contains("open")
			navMenu.forEach(el => el.classList.remove('open'));

			if (active) {
				current.classList.remove('open')
				console.log("active")
			} else {
				current.classList.add('open');
				console.log("close")
			}
		}

	}

	render() {
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		const language = i18n.language

		return (
			<>

				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav">
					<div className="top-bar">
						<div className="container">
							<div className="row d-flex justify-content-between">
								<div className="topbar-left">
									<ul>
										<li style={{ margin: 6 }}><a target='_blank' href="https://www.instagram.com/castordanismanlik?igsh=OHE3eGd2a2xnMHdv"><i className="fa fa-instagram"></i></a></li>
										<li style={{ margin: 6 }}><a  target='_blank' href="https://www.linkedin.com/in/castor-m%C3%BChendislik-stratejik-dan%C4%B1%C5%9Fmanl%C4%B1k-8775182b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fa fa-linkedin"></i></a></li>
										<li style={{ margin: 6 }}><a target='_blank' href="https://www.youtube.com/@CastorDanismanlik"><i className="fa fa-youtube"></i></a></li>
									</ul>
								</div>

								<div className="topbar-right">
									{Object.keys(lngs).map((lng) => (
										<button
											key={lng}
											className={lngs[lng].nativeName === "English" ? "english" : "turkish"}
											style={{
												fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
												background: "none",  // Arka planı kaldır
												border: "none",      // Kenarlığı kaldır
												color: "inherit",     // Metin rengini varsayılana ayarla
												cursor: "pointer"     // İmleci bir göstergeye dönüştür
											}}
											type="submit"
											onClick={() => i18n.changeLanguage(lng)}
										>
											<span
												style={{
													fontSize: "1.5em",  // Bayrak boyutunu ayarla
													marginRight: "5px"  // Bayrak ile düğme arasındaki boşluğu ayarla
												}}
											>
												{lngs[lng].nativeName === "English" ?
													<img style={{ maxWidth: '30px', verticalAlign: 'middle' }} src={En} alt="English flag" /> :
													<img style={{ maxWidth: '30px', verticalAlign: 'middle' }} src={Tr} alt="Turkish flag" />}
											</span>

										</button>
									))}
								</div>


							</div>
						</div>



					</div>



					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to="/"><img  src={Logo} alt="" /></Link>
									
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											<li className="">
												<button id="quik-search-btn" type="button" className="btn-link"></button>
											</li>
											<li>
												<Link to="#" id="cartBtn">

												</Link>

											</li>
											<li className="d-none d-sm-block">
												<Link to="teklifal" className="btn">{t('TeklifAl')}</Link>
											</li>
										</ul>
									</div>
								</div>
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
									<div  className="menu-logo">
										<Link to="/"><img  src={Logo} alt="" /></Link>
									</div>
									<ul className="nav navbar-nav">
										<li ><Link to="/">{t('Anasayfa')}</Link>

										</li>

										<li><Link to="nedenbiz">{t('BizKimiz')}</Link>

										</li>
										<li><Link to="/">{t('DH')}<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li className="add-menu-left">
													<ul>
														{this.state.data3.map((item2) => (
															<li><Link to={{ pathname: '/danismanlikhizmetleri_', state: { _item: item2.Consultancy[0], _item2: item2 } }}>

																{language == "en" ? <span>{item2.Consultancy_Category_TitleEng}</span> :
																	<span>{item2.Consultancy_Category_Title}</span>}
															</Link></li>
														))}

													</ul>
												</li>
											</ul>
										</li>
										<li><Link to="/">{t('MH')}<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li className="add-menu-left">
													<ul>
														{this.state.data4.map((item3) => (
															<li><Link to={{ pathname: '/muhendislikhizmetleri_', state: { _item: item3.EngineeringServices.length> 0? item3.EngineeringServices[0] : [], _item2: item3 } }} >
																{language == "en" ? <span>{item3.EngineeringServices_Category_TitleEng}</span> :
																	<span>{item3.EngineeringServices_Category_Title}</span>}
															</Link></li>
														))}
													</ul>
												</li>
											</ul>
										</li>


										<li><Link to="blog">Blog </Link>

										</li>

										<li><Link to="iletisim">{t('İletişim')}</Link>

										</li>

										{isMobile == true ?	<li><Link to="teklifal">{t('Teklif Al')}</Link>

</li> : null}

									</ul>

								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> */}
					<div className="nav-search-bar">
						<form action="#">
							<input name="search" type="text" className="form-control" placeholder="Type to search" />
							<span><i className="ti-search"></i></span>
						</form>
						<span id="search-remove"><i className="ti-close"></i></span>
					</div>
				</header>
				{/* <!-- Header Top END ==== --> */}
			</>
		);
	}
}
export default withTranslation()(Header1);

