import React, { Component } from 'react';
import Slider from "react-slick";

// Images

import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'; // React ikonlarını içe aktar
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Slider1 extends Component{
	constructor(props) {
		super(props);
		this.state = {	
			data3: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {
			const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getSliderCastor/select/";
			fetch(apiUrl3)
				.then((response) => response.json())
				.then((data3) => {
					this.setState({ data3 });
				})
				.catch((error) => {
					console.error("API'den veri çekme hatası:", error);
				});		
	}

	render(){
		

		const PrevArrow = (props) => {
			const { className, style, onClick } = props;
			return (
				<div className={className} style={{ ...style, display: "flex", alignItems: "center", justifyContent: "center", left: "-20px", top: "40%", transform: "translateY(-50%)" }} onClick={onClick}>
				<FaAngleLeft />
			
			  </div>
			);
		  };
		  
		  // Sağ kaydırma ikonu bileşeni
		  const NextArrow = (props) => {
			const { className, style, onClick } = props;
			return (
				<div className={className} style={{ ...style, display: "flex", alignItems: "center", justifyContent: "center", right: "-20px", top: "40%", transform: "translateY(-50%)" }} onClick={onClick}>
				<FaAngleRight />
			  </div>
			);
		  };

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true, // Otomatik kaydırma
			autoplaySpeed: 20000, 
			prevArrow: <PrevArrow />,
			nextArrow: <NextArrow />
		};
		
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		return(
			
			<>
				
				<Slider {...settings} className="tt-slider slider-one slider-sp0">

				{this.state.data3.map((item) => (
					<div className="slider-item">
						<div className="slider-thumb">
							<img src={this.state.imageUrl+item.SliderImage} alt=""/>
						</div>
						<div className="slider-content text-white text-center">
							<div className="container">
								<div className="content-inner">
								<h6 className="sub-title">Castor Danışmanlık</h6>
						

									<h2 className="title" style={{color:'#003B59'}}><strong>{item.SliderTitle}</strong></h2>
							
								</div>
							</div>
						</div>
					</div>			
				))}
				</Slider>
				
			</>
		);
	}
}

export default withTranslation()(Slider1); // withTranslation ile sarmalandı
