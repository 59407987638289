import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import { withTranslation } from 'react-i18next';
import MapComponent from './MapComponent';
import 'toastr/build/toastr.min.css'; // toastr stillerini içe aktarın
import toastr from 'toastr'; // toastr kütüphanesini içe aktarın

class Contact1 extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}


	showToast= async (type) => {
		let ele = null
		let message = "";
	  
		 if(type == true){
		   ele = "success"
		   message = "Talebiniz tarafımıza iletilmiştir, en yakın sürede sizinle iletişime geçeceğiz"
		} else{
		   ele = "error"
		   message = "type"
		};
		const position = ["toast-top-right"];
		let toastType;
		const title = "Başlık";
	  
	  
		//Close Button
		const closeButton = true;
	  
		//Debug
		const debug = false;
	  
		//Progressbar
		const progressBar = true;
	  
		//Duplicates
		const preventDuplicates = false;
	  
		//Newest on Top
		const newestOnTop = true;
	  
		//position class
		let positionClass = "toast-top-right";
	  
		//Show Easing
		const showEasing = "swing";
	  
		//Hide Easing
		const hideEasing = "linear";
	  
		//show method
		const showMethod = "fadeIn";
	  
		//Hide method
		const hideMethod = "fadeOut";
	  
		//show duration
		const showDuration = 300;
	  
		//Hide duration
		const hideDuration = 1000;
	  
		//timeout
		const timeOut = 5000;
	  
		//extended timeout
		const extendedTimeOut = 1000;
	  
		//Fetch checked Type
	   
		 toastType = ele;
		
	  
		toastr.options = {
		  positionClass: positionClass,
		  timeOut: timeOut,
		  extendedTimeOut: extendedTimeOut,
		  closeButton: closeButton,
		  debug: debug,
		  progressBar: progressBar,
		  preventDuplicates: preventDuplicates,
		  newestOnTop: newestOnTop,
		  showEasing: showEasing,
		  hideEasing: hideEasing,
		  showMethod: showMethod,
		  hideMethod: hideMethod,
		  showDuration: showDuration,
		  hideDuration: hideDuration
		};
	  
		// setTimeout(() => toastr.success(`Settings updated `), 300)
		//Toaster Types
		if (toastType === "info") toastr.info(message, title);
		else if (toastType === "warning") toastr.warning(message, title);
		else if (toastType === "error") toastr.error(message, title);
		else toastr.success(message, title);
	  }

	  
	componentDidMount() {

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactCastor/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}

	submitForm = (event) => {
		event.preventDefault(); // Formun varsayılan davranışını engelle
		
		// Form verilerini al
		const name = document.querySelector('input[name="name"]').value;
		const email = document.querySelector('input[name="email"]').value;
		const phone = document.querySelector('input[name="phone"]').value;
		const subject = document.querySelector('input[name="subject"]').value;
		const message = document.querySelector('textarea[name="message"]').value;

		const data = {
			to_email : 'info@castordanismanlik.com',
			subject : subject,
			message : name + " isimli kişi, "+ phone + " telefon numaralı ve " + email + " mail adresli kişinin mesajı şu şekilde" + message,
			
		}
		

		// JSON verilerini POST isteğiyle gönder
		fetch('https://getjob.stechomeyazilim.info:5101/sendMailCastor/send', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then(response => response.json())
		.then(data => {
			// İşlem başarılıysa
			if (data.success) {
				// İşlem sonucunu ekrana göster
				console.log('İşlem başarıyla tamamlandı:', data.message);
				// İşlem sonrası yapılacakları buraya yazabilirsiniz
			} else {
				this.showToast(true)
				// İşlem başarısızsa
				console.error('İşlem sırasında bir hata oluştu:', data.error);
				// İşlem sonrası yapılacakları buraya yazabilirsiniz
			}
		})
		.catch(error => {
			// Hata oluştuğunda
			this.showToast(true)
			//console.error('Bir hata oluştu:', error);
			// İşlem sonrası yapılacakları buraya yazabilirsiniz
		});
	};
	
	render(){
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t('İletişim')}</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> {t('Anasayfa')}</Link></li>
										<li>{t('İletişim')}</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					
								
					<div className="page-banner contact-page bg-white">
						<div className="container-fuild">

							
							<div className="row m-lr0">


								
							{this.state.data2.length > 0 ? <div className="col-lg-6 col-md-6 p-lr0 d-flex">
							<MapComponent _item = {this.state.data2} />

								</div> : null}
								<div className="col-lg-6 col-md-6 section-sp2 p-lr30">
									<form className="form-area-box ajax-form mw700 m-auto" onSubmit={this.submitForm}>
									<div className="ajax-message"></div>
										<div className="heading-bx">
											<h6 className="title-ext m-b0">Castor</h6>
											<h2 className="title-head m-b0">{t('BU')}</h2>
									
											<p className="head-px2">{t('FED')}</p>
										</div>
										<div className="row placeani">
											<div className="col-lg-6 ">
												<div className="form-group">
													<div className="input-group">
														<label htmlFor="name">{t('İsminiz')}</label>
														<input name="name" type="text" required className="form-control valid-character"/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group"> 
														<label>{t('Emailiniz')}</label>
														<input name="email" type="email" className="form-control" required />
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<label>{t('Telefon')}</label>
														<input name="phone" type="text" required className="form-control int-value"/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<label>{t('Konu')}</label>
														<input name="subject" type="text" required className="form-control"/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<label>{t('Mesaj')}</label>
														<textarea name="message" rows="4" className="form-control" required ></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
											<button onClick={this.submitForm} className="btn button-md">{t('Gönder')}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default withTranslation()(Contact1); // withTranslation ile sarmalandı

