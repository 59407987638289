import React,{useState,useRef,useEffect} from 'react'
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';


function MyComponent(props) {

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const containerStyle = {
    width: `${windowDimensions.width}px`,
    height: `${windowDimensions.height}px`,
  };

  const [isOpen, setIsOpen] = useState(false);
  const markerRef = useRef(null);


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyC51aK_7Y-OBpJzZ-O_5-qDkxTsx8vEW3s"
  })

  const [map, setMap] = React.useState(null)

  useEffect(() => {
    if (markerRef.current) {
      setIsOpen(true); // Marker yüklendiğinde InfoWindow'u aç
    }
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  
    const bounds = new window.google.maps.LatLngBounds();
    const center = {
      lat: props._item[0].Lat,
      lng: props._item[0].Long,
    };
    bounds.extend(center);
  
   // map.fitBounds(bounds);
  
    const minZoom = 15; // Minimum zoom seviyesi
    const maxZoom = 20; // Maksimum zoom seviyesi
    
    // Mevcut zoom seviyesini al
    let zoom = map.getZoom();
    
    // Zoom seviyesini minimum ve maksimum sınırlar arasında kontrol et
    if (zoom < minZoom) {
        map.setZoom(minZoom); // Eğer mevcut zoom seviyesi minZoom'dan küçükse, minZoom'a ayarla
    } else if (zoom > maxZoom) {
        map.setZoom(maxZoom); // Eğer mevcut zoom seviyesi maxZoom'dan büyükse, maxZoom'a ayarla
    }
    
  }, [props._item]);
  
  const center = {
    lat: props._item[0].Lat,
    lng: props._item[0].Long,
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  const handleOpenGoogleMaps = (latitude,longitude) => {
    // Google Haritalar URL'si
    const googleMapsUrl = `https://www.google.com/maps?q=${ props._item[0].Lat},${props._item[0].Long}`;

    // Yeni sekmede Google Haritalar'ı aç
    window.open(googleMapsUrl, '_blank');
  };

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
<Marker
        position={center}
        ref={markerRef}
      >
       
          <InfoWindow onCloseClick={() => setIsOpen(false)}>
            <button onClick={handleOpenGoogleMaps}>
              <h6>Castor</h6>
              <p>Ünalan, Emaar Square AVM, Libadiye Cd. No:88, Üsküdar / İstanbul</p>
            </button>
          </InfoWindow>
       
      </Marker>
        <></>
      </GoogleMap>
  ) : <></>
}

export default React.memo(MyComponent)